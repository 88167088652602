@import "../node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: Georgia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $yellow-100;
  /* Margin bottom by footer height */
  /*margin-top: 70px;*/
  /*margin-bottom: 60px;*/
}

@media (min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}

nav .container {
  font-family: var(--bs-font-monospace);
}

nav {
  border-bottom: $teal-600 solid 4px;
}

//h1, h2, h3, h4, h5, h6 {
//  font-family: var(--bs-font-monospace);
//}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 80%;
}

.very-small {
  font-size: small;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

/*body > .container {*/
/*    padding: 60px 15px 0;*/
/*}*/

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.breadcrumb {
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: #999d9d solid thin;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-light-gray {
  background-color: #EEE;
}

.container {
  font-size: larger;
  font-family: Georgia, serif;
}